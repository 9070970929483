//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    // 節點信息
    documentNode: {
      type: Object,
      default() {
        return {}
      }
    },
    // 操作類型
    todoType: {
      type: String,
      default: ''
    },
    // 當前節點的序號
    documentNodeIndex: {
      type: Number,
      default: 0
    },
    // 當前流程節點序號
    stepIndex: {
      type: Number,
      default: 0
    },
    // 公文接收狀態
    recipientStatus: {
      type: Number,
      default: 0
    },
    isCanSend: {
      type: Boolean
    }
  },
  computed: {
    isFinalyNode() {
      return this.documentNode.sort === 99 &&
          this.todoType === 'dealWith' &&
          this.documentNodeIndex === this.stepIndex &&
          this.recipientStatus === 0
    }
  },
  methods: {
    carbonCopy() {
      this.$emit('carbonCopy')
    }
  }
}
